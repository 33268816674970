import appraisalFilesRoute from "pages/AppraisalFiles/route";
import accountsRoute from "pages/Accounts/route";
import accountroleRoute from "pages/AccountManagement/AccountRole/route";
import listfunctionRoute from "pages/AccountManagement/ListFunction/route";
// import accountDetailRoute from "pages/AccountDetail/route";
import accountDetailRoute from "pages/AccountDetailUpdate/route";
import pricesRoute from "pages/Prices/route";
// import appraisalFilesGiveRoute from "pages/AppraisalFilesGive/route";
import appraisalFilesGiveUpdateRoute from "pages/AppraisalFilesGive/route";
// import appraisalFilesReceiveRoute from "pages/AppraisalFilesReceive/route";
import appraisalFilesReceiveUpdateRoute from "pages/AppraisalFilesReceive/route";
import configCategoriesRoute from "pages/ConfigCategories/route";
import expertisePriceAssetRoute from "pages/ExpertisePriceAsset/route";
import organizationDetailRoute from "pages/OrganizationDetail/route";
import statisticRoute from "pages/Statistic/route";
import cadresRoute from "pages/Cadres/route";
import fastAppraisalFileRoute from "pages/FastAppraisalFiles/route";
import settingRoute from "pages/Setting/route";
import appraisalFileDetailRoute from "pages/AppraisalFileDetail/route";
import adviseRoute from "pages/Advise/route";

import PriceSharedLandUsingCreateRoute from "pages/PriceShared/AssetCreate/LandUsing/route";
import PriceSharedAppartmentCreateRoute from "pages/PriceShared/AssetCreate/Appartment/route";
import PriceSharedRoadVehicleCreateRoute from "pages/PriceShared/AssetCreate/RoadVehicle/route";
import PriceSharedWaterVehicleCreateRoute from "pages/PriceShared/AssetCreate/WaterWayVehicle/route";
import PriceSharedDeviceCreateRoute from "pages/PriceShared/AssetCreate/Device/route";
import PriceSharedProjectAssetRoute from "pages/PriceShared/AssetCreate/ProjectAsset/route";
import PriceSharedProjectAssetEstimateRoute from "pages/PriceShared/AssetCreate/EstimateAsset/route";
import priceSharedAssetListApprovalWaitingRoute from "pages/PriceShared/AssetListApprovalWaiting/route";
import priceSharedAssetListApprovalDenyRoute from "pages/PriceShared/AssetListAppovalDeny/route";
import priceSharedApproveRoute from "pages/PriceShared/ApprovePriceShared/route";
import PriceSharedLandUsingApproveRoute from "pages/PriceShared/ApproveDenyAsset/LandUsing/route";
import PriceSharedApartmentApproveRoute from "pages/PriceShared/ApproveDenyAsset/Apartment/route";
import PriceSharedRoadVehicleApproveRoute from "pages/PriceShared/ApproveDenyAsset/RoadVehicle/route";
import PriceSharedWaterVehicleApproveRoute from "pages/PriceShared/ApproveDenyAsset/WaterVehicle/route";
import PriceSharedDeviceApproveRoute from "pages/PriceShared/ApproveDenyAsset/Device/route";
import PriceSharedProjectApproveRoute from "pages/PriceShared/ApproveDenyAsset/ProjectAsset/route";
import PriceSharedEstimateApproveRoute from "pages/PriceShared/ApproveDenyAsset/EstimateAsset/route";

import priceSpecificUsingLandRoute from "pages/PriceSpecific/PriceSpecificUsingLand/route";
import priceSpecificUsingLandDetailRoute from "pages/PriceSpecific/PriceSpecificUsingLandDetail/route";
import priceSpecificAppartmentRoute from "pages/PriceSpecific/PriceSpecificAppartment/route";
import priceSpecificAppartmentDetailRoute from "pages/PriceSpecific/PriceSpecificAppartmentDetail/route";
import priceSpecificDeviceRoute from "pages/PriceSpecific/PriceSpecificDevice/route";
import priceSpecificDeviceDetailRoute from "pages/PriceSpecific/PriceSpecificDeviceDetail/route";
import priceSpecificRoadVehicleRoute from "pages/PriceSpecific/PriceSpecificRoadVehicle/route";
import priceSpecificRoadVehicleDetailRoute from "pages/PriceSpecific/PriceSpecificRoadVehicleDetail/route";
import priceSpecificWaterWayRoute from "pages/PriceSpecific/PriceSpecificWarterWay/route";
import priceSpecificWaterVehicleDetailRoute from "pages/PriceSpecific/PriceSpecificWaterVehicleDetail/route";
import priceSpecificProjectRoute from "pages/PriceSpecific/PriceSpecificProjectAsset/route";
import priceSpecificProjectDetailRoute from "pages/PriceSpecific/PriceSpecificProjectAssetDetail/route";
import priceSpecificEstimateRoute from "pages/PriceSpecific/PriceSpecificEstimate/route";
import priceSpecificEstimateDetailRoute from "pages/PriceSpecific/PriceSpecificEstimateDetail/route";

import priceFrameRoute from "pages/PriceFrame/route";

import appraisalFilesAutoRoute from "pages/AppraisalFilesAuto/route";
import appraisalFilesHandMade from "pages/AppraisalFilesHandMade/route";
import appraisalFileCreateNewRoute from "pages/AppraisalFileCreate/route";
import signWithCustomerRoute from "pages/SignWithCustomer/route";
import ReportViewLevelOneRoute from "pages/ReportViewManagement/ReportViewLevelOne/route";
import appraisalResultsRoute from "pages/AppraisalResults/route";
import userListRoute from "pages/AccountManagement/UserList/route";
import userAuthorizationRoute from "pages/AccountManagement/UserAuthorization/route";
import userFunctionRoute from "pages/AccountManagement/UserFunction/route";
import appendixMethodsRoute from "pages/AppendixMethods/route";

import categoryKpiRoute from "pages/CategoryManage/CategoryKpi/route";
import categoryRegionsRoute from "pages/CategoryManage/CategoryRegions/route";
import categoryBussinessFeeRoute from "pages/BussinessFee/route";
import categoryRiskRoute from "pages/CategoryManage/CategoryRisk/route";
import categoriesRoute from "pages/CategoryManage/CategoriesAdministratives/route";

// import categoriesSlaRoute from "pages/CategoryManage/CategorySla/route";
import categoriesAssetLevelRoute from "pages/CategoryManage/CategoriesAssetLevel/route";

import categoryInvestRoute from "pages/CategoryManage/CategoryInvest/route";

import categoryTransaction from "pages/CategoryManage/CategoryTransaction/route";

import categoryProvinceRegionRoute from "pages/CategoryManage/CategoryProvinceRegion/route";
import holidaysRoute from "pages/Holidays/route";
import accessHistoryRoute from "pages/AccessHistory/route";
import categoryFeeRoute from "pages/CategoryManage/CategoryFee/route";
import systemParamsRoute from "pages/SystemParams/route";
//import systemFastExpertiseRoute from "pages/SystemFastExpertise/route";
import systemFastExpertiseRouteUpdate from "pages/SystemFastExpertiseUpdate/route";
// import categoryConfigApprove from "pages/CategoryConfigApprove/route";
// import categoryLegal from "pages/CategoryLegal/route";
// import categoryPurposeRoute from "pages/CategoryPurpose/route";
import landFastExpertise from "pages/FastExpertise/LandTable/route";
import {
  landFastExpertiseCreateRoute,
  landFastExpertiseEditRoute,
  landFastExpertiseViewRoute,
} from "pages/FastExpertise/LandCreate/route";

import landFastExpertiseDetailRoute from "pages/FastExpertise/LandDetail/route";
import apartmentFastExpertise from "pages/FastExpertise/ApartmentTable/route";
import {
  apartmentFastExpertiseCreateRoute,
  apartmentFastExpertiseEditRoute,
  apartmentFastExpertiseViewRoute,
} from "pages/FastExpertise/ApartmentCreate/route";
import apartmentFastExpertiseDetailRoute from "pages/FastExpertise/ApartmentDetail/route";
import vehicleFastExpertise from "pages/FastExpertise/VehicleTable/route";
import {
  vehicleFastExpertiseCreateRoute,
  vehicleFastExpertiseEditRoute,
  vehicleFastExpertiseViewRoute,
} from "pages/FastExpertise/VehicleCreate/route";

import vehicleFastExpertiseDetailRoute from "pages/FastExpertise/VehicleDetail/route";
import categoryLegal from "pages/CategoryManage/CategoryLegal/route";
import categoryPurposeRoute from "pages/CategoryManage/CategoryPurpose/route";
import accountantFeeNotifications from "pages/AccountantFeeNotifications/route";
import categoryConfigApprove from "pages/CategoryManage/CategoryConfigApprove/route";
import viewReportPrintRoute from "pages/ViewReportPrint/route";
import accountantCollectSpent from "pages/AccountantCollectSpent/route";

import accountantFeeNotificationsUpdate from "pages/AccountantFeeNotificationsList/route";
import AccountantDebtFollow from "pages/AccountantDebtFollow/route";
import AccountantManualEdit from "pages/AccountantManualEdit/route";
import AccountantManualEditDetail from "pages/AccountantManualEditDetail/route";
import accountantDebtDetail from "pages/AccountantDebtDetail/route";
import DebtCollection from "pages/DebtCollection/route";
import UnidentifiedProfile from "pages/UnidentifiedProfile/route";
import accountantApproved from "pages/AccountantApproved/route";
import accountantCreateCoSp from "pages/AccountantCreateCoSp/route";

import reportsCompleteFile from "pages/Reports/ReportsCompletedFile/route";
import reportsAll from "pages/Reports/ReportsAll/route";
import reportsQuantity from "pages/Reports/ReportsMonthlyArising/route";
import reportsKPIDetails from "pages/Reports/ReportsKPIS/route";
import reportsPersonal from "pages/Reports/ReportsPersonal/route";
import reportsLate from "pages/Reports/ReportsLate/route";
import reportsDebt from "pages/Reports/ReportsDebt/route";

import categoryCommit from "pages/CategoryManage/CategoryCommit/route";

import rePricingRoute from "pages/RePricing/route";
import vehicleBrandRoute from "pages/VehicleBrand/route";
import CategoryConstructionRoute from "pages/CategoryManage/CategoryConstruction/route";

const route = [
  appraisalFilesRoute,
  listfunctionRoute,
  accountroleRoute,
  accountsRoute,
  accountDetailRoute,
  pricesRoute,
  expertisePriceAssetRoute,
  appraisalFilesGiveUpdateRoute,
  appraisalFilesReceiveUpdateRoute,
  organizationDetailRoute,
  statisticRoute,
  cadresRoute,
  fastAppraisalFileRoute,
  configCategoriesRoute,
  settingRoute,
  appraisalFileDetailRoute,
  adviseRoute,
  PriceSharedLandUsingCreateRoute,
  priceSharedAssetListApprovalWaitingRoute,
  priceSharedAssetListApprovalDenyRoute,
  priceSharedApproveRoute,
  priceSpecificUsingLandRoute,
  priceSpecificUsingLandDetailRoute,
  priceFrameRoute,
  categoriesRoute,
  appraisalFilesHandMade,
  appraisalFilesAutoRoute,
  signWithCustomerRoute,
  appraisalResultsRoute,
  userListRoute,
  userAuthorizationRoute,
  userFunctionRoute,
  appendixMethodsRoute,
  appraisalFileCreateNewRoute,
  categoryKpiRoute,
  categoryRegionsRoute,
  categoryProvinceRegionRoute,
  categoryBussinessFeeRoute,
  categoryRiskRoute,
  holidaysRoute,
  accessHistoryRoute,
  categoryFeeRoute,
  systemParamsRoute,
  //systemFastExpertiseRoute,
  systemFastExpertiseRouteUpdate,
  categoryConfigApprove,
  categoryLegal,
  categoryPurposeRoute,
  landFastExpertise,
  landFastExpertiseCreateRoute,
  landFastExpertiseEditRoute,
  landFastExpertiseViewRoute,
  landFastExpertiseDetailRoute,
  accountantFeeNotifications,
  priceSpecificAppartmentRoute,
  priceSpecificDeviceRoute,
  priceSpecificDeviceDetailRoute,
  priceSpecificRoadVehicleRoute,
  // categoriesSlaRoute,
  categoriesAssetLevelRoute,
  ReportViewLevelOneRoute,
  categoryInvestRoute,
  priceSpecificAppartmentDetailRoute,
  apartmentFastExpertiseCreateRoute,
  apartmentFastExpertiseEditRoute,
  apartmentFastExpertiseViewRoute,
  apartmentFastExpertiseDetailRoute,
  apartmentFastExpertise,
  vehicleFastExpertiseCreateRoute,
  vehicleFastExpertiseEditRoute,
  vehicleFastExpertiseViewRoute,
  vehicleFastExpertiseDetailRoute,
  vehicleFastExpertise,
  priceSpecificRoadVehicleDetailRoute,
  PriceSharedAppartmentCreateRoute,
  PriceSharedRoadVehicleCreateRoute,
  PriceSharedWaterVehicleCreateRoute,
  priceSpecificWaterVehicleDetailRoute,
  PriceSharedDeviceCreateRoute,
  PriceSharedProjectAssetRoute,
  PriceSharedProjectAssetEstimateRoute,
  priceSpecificWaterWayRoute,
  priceSpecificProjectRoute,
  priceSpecificProjectDetailRoute,
  priceSpecificEstimateRoute,
  priceSpecificEstimateDetailRoute,
  PriceSharedLandUsingApproveRoute,
  PriceSharedApartmentApproveRoute,
  PriceSharedRoadVehicleApproveRoute,
  PriceSharedWaterVehicleApproveRoute,
  PriceSharedDeviceApproveRoute,
  PriceSharedProjectApproveRoute,
  PriceSharedEstimateApproveRoute,
  viewReportPrintRoute,
  categoryTransaction,
  accountantFeeNotificationsUpdate,
  AccountantDebtFollow,
  DebtCollection,
  UnidentifiedProfile,
  accountantCollectSpent,
  accountantApproved,
  accountantCreateCoSp,
  accountantDebtDetail,
  reportsCompleteFile,
  reportsAll,
  reportsQuantity,
  reportsKPIDetails,
  reportsPersonal,
  reportsLate,
  reportsDebt,
  categoryCommit,
  rePricingRoute,
  vehicleBrandRoute,
  AccountantManualEdit,
  AccountantManualEditDetail,
  CategoryConstructionRoute,
];
export default route;
