enum DATE_TIME_FORMAT {
  day = "DD/MM/YYYY",
  day_2_start = "DD-MM-YYYY - 00:00:00",
  day_2_end = "DD-MM-YYYY - 23:59:59",
  dateTime = "hh:mm - DD/MM/YYYY",
  momentTime = "DD-MM-YYYY - HH:mm:ss",
  momentTimeEng = "YYYY-MM-DD - HH:mm:ss.SSS",
  revertDay = "YYYY-MM-DD",
  dateTimeFull = "DD-MM-YYYY HH:mm:ss",
  momentTimeFull = "DD-MM-YYYY HH:mm:ss"
}
export { DATE_TIME_FORMAT };
