import useSWR from "swr";

export const useAssetLevelThree = (assetLevelTwo: number | null) => {
  const { data, error, isLoading, mutate } = useSWR(
    assetLevelTwo
      ? `/assets/api/v1/assetLevelThree/get_by_asset_level_two/${assetLevelTwo}`
      : null,
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
    }
  );

  return {
    data,
    error,
    isLoading,
    mutate,
  };
};
