import {
  CameraOutlined as camera,
  ArrowRightOutlined as arrowRight,
  SearchOutlined as search,
  DownloadOutlined as download,
  UploadOutlined as upload,
  PlusOutlined as add,
  UserOutlined as user,
  SaveOutlined as save,
  AudioOutlined as mic,
  AudioMutedOutlined as mute,
  SoundOutlined as speaker,
  PauseOutlined as pause,
  CaretRightOutlined as play,
  BarsOutlined as bars,
  RightOutlined as right,
  UpOutlined as up,
  CheckCircleFilled as checkFilled,
  CloseCircleFilled as closeFilled,
  DeleteOutlined as remove,
  MinusOutlined as sub,
  CloseOutlined as close,
  ClockCircleOutlined as clock,
  DownOutlined as down,
  MoreOutlined as more,
  MailOutlined as mail,
  FileTextOutlined as file,
  CalendarOutlined as calender,
  PrinterOutlined as printer,
  CopyOutlined as copy,
  EditOutlined as edit,
  LogoutOutlined as logout,
  BellOutlined as bell,
  SettingOutlined as setting,
  MenuOutlined as menu,
  MenuUnfoldOutlined as menuUnfold,
  MenuFoldOutlined as menuFold,
  StepBackwardOutlined as stepBackward,
  CheckCircleOutlined as checkOutLine,
  ExportOutlined as exportOutlined,
  EyeOutlined as eyeOutlined,
  StepForwardOutlined as forward,
  FileDoneOutlined as fileDone,
  CloseCircleOutlined as closeCircle,
  ReloadOutlined as reload,
  SyncOutlined as sync,
  DoubleRightOutlined as doubleRight,
  DoubleLeftOutlined as doubleLeft,
  LockOutlined as lock,
  MoneyCollectOutlined as money,
  CheckOutlined as check,
  LoadingOutlined as loadingOutlined,
  SendOutlined as sendOutlined,
  CaretRightOutlined as caretRightOutlined,
} from "@ant-design/icons";

export default {
  search,
  download,
  upload,
  add,
  user,
  save,
  mic,
  mute,
  speaker,
  pause,
  play,
  bars,
  right,
  up,
  calender,
  checkFilled,
  closeFilled,
  remove,
  sub,
  close,
  clock,
  down,
  more,
  mail,
  file,
  printer,
  copy,
  edit,
  logout,
  bell,
  setting,
  menu,
  menuFold,
  menuUnfold,
  stepBackward,
  arrowRight,
  checkOutLine,
  exportOutlined,
  eyeOutlined,
  forward,
  fileDone,
  closeCircle,
  reload,
  camera,
  sync,
  doubleLeft,
  doubleRight,
  lock,
  money,
  check,
  loadingOutlined,
  sendOutlined,
  caretRightOutlined,
};
