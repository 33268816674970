export const CATEGORY_LEGAL = "/category-legal";
export const DEFAULT = "/";
export const LOGIN = "/login";
export const EXPERTISE_PRICE_ASSET_INFO = "/expertises/price-asset";
export const STATISTIC = "/statistics";
export const ACCOUNT = "/accounts";
export const ACCOUNTROLE = "/user-role";
export const LISTFUNCTION = "/user/ds-chucnang";
export const ORGANIZATION = "/organizations";
export const ROLE = "/roles";
export const USER_LIST = "/users";
export const USER_DIARY = "/user-diary";
export const USER_AUTHORIZATION = "/user-authorization";
export const USER_FUNCTION = "/user-function";
export const CONFIGCATEGORIES = "/config-categories";
export const REGIONS_FUNCTION = "/category-area";
export const ROLE_DETAIL = "/roles/:id";
export const PRICE = "/prices/:id";
export const SETTING = "/setting";
export const CADRES = "/cadres";
export const ACCOUNT_DETAIL = "/account-detail";
export const ORGANIZATION_DETAIL = "/organization/:id";
export const CREATE_APPRAISAL_FILE_ASSETS = "/appraisal-file-asset/create";
export const EDIT_APPRAISAL_FILE_ASSETS = "/appraisal-file-asset/:id";
export const PROPERTY_PRICES = "/property-prices";
export const FAST_APPRAISAL_FILES = "/fast-expertise";
export const CREATE_FAST_EXPERTISE = "/fast-expertise/create";
export const CREATE_FAST_EXPERTISE_LAND_ESTATE =
  "/fast-expertise/land-estate-real/create";
export const EDIT_FAST_EXPERTISE = "/fast-expertise/:id";
export const APPRAISAL_FILES = "/appraisal-files";
export const APPRAISAL_FILES_RECEIVE = "/appraisal-files/receive";
export const APPRAISAL_FILES_GIVE = "/appraisal-files/give";
export const CREATE_ASSET_REALESTATE = "/prices/create/realestate";
export const ADD_ASSET = "/appraisal-files/add-asset";
export const TRANSPORTATION_ROAD_CAR = "/asset/transport-road-card/:id";
export const CREATE_PRICE_ASSET_LAND_REAL_ESTATE =
  "/price/asset-land-real/create";
export const PRICE_SPECIFIC_RENT_LAND = "/prices-specific/rent-land";
export const PRICE_SPECIFIC_VILLA = "/prices-specific/villa";
export const PRICE_SPECIFIC_APPARTMENT_GCN = "/prices-specific/apartment-gcn";
export const PRICE_SPECIFIC_HOUSE = "/prices-specific/house";
export const PRICE_SPECIFIC_MACHINES = "/prices-specific/machines";
export const PRICE_SPECIFIC_GOODS = "/prices-specific/goods";
export const PRICE_SPECIFIC_ORTHER = "/prices-specific/other";

export const CATEGORY_PURPOSE = "/category-purpose";
export const CREATE_PRICE_ASSET_VEHICLE = "/price/asset-vehicle/create";
export const CREATE_PRICE_ASSET_APPARTMENT = "/price/asset-appartment/create";
export const ADVISE = "/advise";

export const PRICE_SHARED_LAND_USING_CREATE_ASSET = "/prices-shared/bds";
export const PRICE_SHARED_APPARTMENT_CREATE_ASSET = "/prices-shared/chcc";
export const PRICE_SHARED_ROAD_VEHICLE_CREATE_ASSET = "/prices-shared/duong-bo";
export const PRICE_SHARED_WATER_VEHICLE_CREATE_ASSET =
  "/prices-shared/duong-thuy";
export const PRICE_SHARED_DEVICE_CREATE_ASSET = "/prices-shared/may-moc";
export const PRICE_SHARED_PROJECT_CREATE_ASSET = "/prices-shared/du-an";
export const PRICE_SHARED_ESTIMATE_CREATE_ASSET = "/prices-shared/du-toan";
export const PRICE_SHARED_PENDIND = "/prices-shared/pending";
export const PRICE_SHARED_DENY = "/prices-shared/deny";
export const APPROVE_PRICE_SHARED = "/prices-shared/approve";
export const PRICE_SHARED_APPROVE_LAND_USING = "/prices-shared/approve/bds/:id";
export const PRICE_SHARED_APPROVE_LAND_USING_EDIT =
  "/prices-shared/bds/edit/:id";
export const PRICE_SHARED_APPROVE_APPARTMENT =
  "/prices-shared/approve/chcc/:id";
export const PRICE_SHARED_APPROVE_APPARTMENT_EDIT =
  "/prices-shared/chcc/edit/:id";
export const PRICE_SHARED_APPROVE_ROADVEHICLE =
  "/prices-shared/approve/ptdb/:id";
export const PRICE_SHARED_APPROVE_ROADVEHICLE_EDIT =
  "/prices-shared/ptdb/edit/:id";
export const PRICE_SHARED_APPROVE_WATERVEHICLE =
  "/prices-shared/approve/ptdt/:id";
export const PRICE_SHARED_APPROVE_WATERVEHICLE_EDIT =
  "/prices-shared/ptdt/edit/:id";
export const PRICE_SHARED_APPROVE_DEVICE = "/prices-shared/approve/may-moc/:id";
export const PRICE_SHARED_APPROVE_DEVICE_EDIT =
  "/prices-shared/may-moc/edit/:id";
export const PRICE_SHARED_APPROVE_PROJECT = "/prices-shared/approve/du-an/:id";
export const PRICE_SHARED_APPROVE_PROJECT_EDIT =
  "/prices-shared/du-an/edit/:id";
export const PRICE_SHARED_APPROVE_ESTIMATE =
  "/prices-shared/approve/du-toan/:id";
export const PRICE_SHARED_APPROVE_ESTIMATE_EDIT =
  "/prices-shared/du-toan/edit/:id";

export const PRICE_SPECIFIC_USING_LAND = "/prices-specific/bds";
export const PRICE_SPECIFIC_USING_LAND_DETAIL = "/prices-specific/bds/:id";
export const PRICE_SPECIFIC_APPARTMENT = "/prices-specific/chcc";
export const PRICE_SPECIFIC_APPARTMENT_DETAIL = "/prices-specific/chcc/:id";
export const PRICE_SPECIFIC_SMALL_CAR = "/prices-specific/oto-con";
export const PRICE_SPECIFIC_SMALL_CAR_DETAIL = "/prices-specific/oto-con/:id";
export const PRICE_SPECIFIC_COACH_CAR = "/prices-specific/xe-khach";
export const PRICE_SPECIFIC_TRUCK = "/prices-specific/xe-tai";
export const PRICE_SPECIFIC_RICKSHAW = "/prices-specific/xe-keo";
export const PRICE_SPECIFIC_SO_MI_RO_MOOC = "/prices-specific/ro-mooc";
export const PRICE_SPECIFIC_TWO_WHEEL = "/prices-specific/hai-banh";
export const PRICE_SPECIFIC_ROAD_VEHICLE = "/prices-specific/duong-bo";
export const PRICE_SPECIFIC_ROAD_VEHICLE_DETAIL =
  "/prices-specific/duong-bo/:id";
export const PRICE_SPECIFIC_DEVICE = "/prices-specific/may-moc";
export const PRICE_SPECIFIC_DEVICE_DETAIL = "/prices-specific/may-moc/:id";
export const PRICE_SPECIFIC_WATER_WAY = "/prices-specific/duong-thuy";
export const PRICE_SPECIFIC_WATER_WAY_DETAIL =
  "/prices-specific/duong-thuy/:id";
export const PRICE_SPECIFIC_PROJECT = "/prices-specific/du-an";
export const PRICE_SPECIFIC_PROJECT_DETAIL = "/prices-specific/du-an/:id";
export const PRICE_SPECIFIC_ESTIMATE_PRICE = "/prices-specific/du-toan";
export const PRICE_SPECIFIC_ESTIMATE_PRICE_DETAIL =
  "/prices-specific/du-toan/:id";

export const PRICE_FRAME = "/prices-frame";
export const CATEGORIES_ADMINISTRATIVES = "/category-administratives";
export const APPRAISAL_FILE_DETAIL = "/appraisal-file-detail/:id";
export const APPRAISAL_FILES_AUTO = "/appraisal-files/auto";
export const APPRAISAL_FILES_HANDMADE = "/appraisal-files/handmade";
export const APPRAISAL_FILES_CREATE = "/appraisal-files/create-new";
export const SIGN_WITH_CUSTOMER = "/sign";
export const REPORT_VIEW_LEVEL_ONE = "/report-view-level-one/:id";
export const APPRAISAL_RESULTS = "/appraisal-results/:id";
export const APPENDIX_METHODS = "/appendix-methods";

export const CATEGORY_KPI = "/category-kpi";
export const CATEGORY_BUSSINESS_FEE = "/category-bussiness-fee";
export const CATEGORY_RISK = "/category-risk";
export const CATEGORY_CONFIG_APPROVE = "/category-config-approve";
export const CATEGORY_FEE = "/category-fee";
export const CATEGORY_REGIONS = "/category-regions";
// export const CATEGORY_SLA = "/category-sla";
export const CATEGORY_ASSET_LEVEL = "/category-level-asset";
export const CATEGORY_INVEST = "/category-invest";

export const SYSTEM_LOGIN = "/system-login";
export const HOLIDAYS = "/category-dayoffs";
export const SYSTEM_PARAMETERS = "/system-parameters";
export const SYSTEM_FAST_EXPERTISE = "/system-fast-expertise";
export const FAST_EXPERTISE_LAND_TOWNHOUSE = "/fast-expertise/nha-pho";
export const FAST_EXPERTISE_LAND_TOWNHOUSE_CREATE =
  "/fast-expertise/nha-pho/create-new";
export const FAST_EXPERTISE_LAND_TOWNHOUSE_EDIT =
  "/fast-expertise/nha-pho/edit/:id";
export const FAST_EXPERTISE_LAND_TOWNHOUSE_VIEW =
  "/fast-expertise/nha-pho/view/:id";
export const FAST_EXPERTISE_LAND_TOWNHOUSE_DETAIL =
  "/fast-expertise/nha-pho/detail";
export const FAST_EXPERTISE_VEHICLE = "/fast-expertise/xe";
export const FAST_EXPERTISE_VEHICLE_CREATE = "/fast-expertise/xe/create-new";
export const FAST_EXPERTISE_VEHICLE_EDIT = "/fast-expertise/xe/edit/:id";
export const FAST_EXPERTISE_VEHICLE_VIEW = "/fast-expertise/xe/view/:id";
export const FAST_EXPERTISE_VEHICLE_DETAIL = "/fast-expertise/xe/detail";
export const FAST_EXPERTISE_APARTMENT = "/fast-expertise/chung-cu";
export const FAST_EXPERTISE_APARTMENT_CREATE =
  "/fast-expertise/chung-cu/create-new";
export const FAST_EXPERTISE_APARTMENT_EDIT =
  "/fast-expertise/chung-cu/edit/:id";
export const FAST_EXPERTISE_APARTMENT_VIEW =
  "/fast-expertise/chung-cu/view/:id";
export const FAST_EXPERTISE_APARTMENT_DETAIL =
  "/fast-expertise/chung-cu/detail";
export const VIEW_REPORT_PRINT_PAGE = "/view-report-print";
export const AZURE_REDIRECT = "/signin-oidc";
export const CATEGORY_TRANSACTION = "/category-transaction";

export const ACCOUNTANT_FEE_NOTIFICATIONS = "/accountant/lap-bao-phi";
export const ACCOUNTANT_DEBT_DETAIL =
  "/accountant/chi-tiet-doanh-thu-cong-no/:id";
export const ACCOUNTANT_MANUAL_EDIT_DETAIL = "/accountant/sua-phi-thu-cong/:id";
export const ACCOUNTANT_FEE_NOTIFICATIONS_LIST =
  "/accountant/lap-bao-phi-stage";
export const ACCOUNTANT_DEBT_FOLLOW = "/accountant/theo-doi-bao-phi";
export const ACCOUNTANT_MANUAL_EDIT = "/accountant/sua-phi-thu-cong";
export const DEBT_COLLECTION = "/accountant/doanh-thu-cong-no";
export const UNIDENTIFIED_PROFILE = "/accountant/ho-so-chua-xac-dinh";

export const ACCOUNTANT_COLLECT_SPENT = "/accountant/cac-khoan-thu-chi";
export const ACCOUNTANT_APPROVED = "/accountant/duyet-thu-chi";
export const ACCOUNTANT_CREATE_CO_SP = "/accountant/phieu-thu-chi";

export const REPORT_COMPLETED_FILE = "/reports/completed-files";
export const REPORT_QUANTITY = "/reports/quantity";
export const REPORT_ALL = "/reports/all";
export const REPORT_KPI_DETAILS = "/reports/kpis";
export const REPORT_PERSONAL = "/reports/personal";
export const REPORT_LATE = "/reports/late";
export const REPORT_DEBT = "/reports/cong-no";
export const CATEGORY_COMMIT = "/category-commit";
export const NOT_FOUND = "*";

export const RE_PRICING = "/tai_dinh_gia"; // tái định giá HS thủ công
export const VEHICLE_BRAND = "/nhan_hieu";

export const CATEGORY_CONSTRUCTION = "/constructionName"; // mô tả đặc tính kỹ thuật
